<!-- eslint-disable -->
<template>
<!-- ***** START Enter Water Reading Dialog ***** -->
  <base-material-card
    color="blue"
    icon="mdi-water"
    :title="title"
    class="px-5 py-3 mt-10"
  >
    <v-form class="mt-5">
      <v-menu
        ref="menu"
        v-model="menu"
        offset-y
        min-width="320px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="entry.de_entrydate"
            label="Reading Date"
            v-bind="attrs"
            v-on="on"
            persistent-hint
          ></v-text-field> 
        </template>
        <v-date-picker 
          v-model="entry.de_entrydate" 
          no-title 
          @input="menu = false"
          scrollable>
        </v-date-picker>
      </v-menu>
<!-- ***** Dynamically Generate Data Input Fields ***** -->
    <template v-for="(column, i) in vcolumns">
      <v-text-field
        :label="column.text"
        v-model="vcolumns[i].value"
        class="pt-0 mb-3"
        :rules="entry.de_inputrules"
        :key="i"
        :hint="vcolumns[i].units"
        persistent-hint
     />
    </template>
      <v-text-field
        label="ReadingTime"
        v-model="entry.de_entrytime"
        persistent-hint
        clearable
      />
      <v-time-picker use-seconds format="24hr" font-size="40"  v-model="entry.de_entrytime" color="green lighten-1" header-color="green lighten-1" width="275" > </v-time-picker>


    </v-form>

    <v-card-actions class="pl-0" >
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        min-width="50"
        text @click="$emit('cancelentry')"
        >
        Dismiss
      </v-btn>
      <v-btn
        color="success"
        min-width="50"
        text 
        @click="onSaveDataEntry"
        >
        Save
      </v-btn>
      <v-btn
        color="red"
        min-width="25"
        text 
        @click="debuginstance()"
        >
        Debug
      </v-btn>
    </v-card-actions>
  </base-material-card>   
<!-- ***** END Enter Water Usage Dialog ***** -->
</template>

<script>
/* eslint-disable */
//************************************************
//************************************************
import moment from 'moment'
import DatalogService from '../../services/DatalogService'

export default {
  name: 'DatalogEntryForm',
  components: { },
  mixins: [],

  //************************************************
  props: {
    saveTime: {
      type: String,
      default:  moment().format('YYYY-MM-DD HH:mm:ss') },
    latest_saveTime: {
      type: String,
      default: ''},
    pheaders: {
      type: Array,
      default: null },
    title: {
      type: String,
      default: 'Enter Water Readings'},
  },

  data () { return {
    showdialog: false,
    menu: false,
    modal: false,
    date: null,
    entry: { 
      de_savetime: this.saveTime,
      de_entrydate: null,
      de_entrytime: null,
    },
    vcolumns: [
      // {
      // label: "House Water Usage",
      // value: "112",
      // units: "gal",},
      // {
      // label: "House Water Fill",
      // value: "143",
      // units: "gal",}
    ],
  }},
//************************
// WATCH
//************************
watch: {
},
//************************
// METHODS
//************************
  methods: {
    debuginstance (val) {
    debugger
  },
//************ Initialize the form from props and the current date/time
  onResetForm () {
    // console.log("onResetForm")      
    this.entry.de_entrydate = moment(this.saveTime).format('YYYY-MM-DD')        
    this.entry.de_entrytime = moment(this.saveTime).format('HH:mm:ss') 
  }, 
//************ Save the entered readings
  async onSaveDataEntry () {
  try {
    let createdentries = []
    let entrydate = this.entry.de_entrydate
    let entrytime = this.entry.de_entrytime
    let entrysavetime = entrydate + " " + entrytime
    let vcolumns = this.vcolumns
  // Loop through saving the entries
    for (let i=0; i < vcolumns.length; i++) {
      if (vcolumns[i].value != "") {
        // Something was entered for this entry, so save it
        // TBD improve validation on this data entry
        const newentry = await DatalogService.createEntry(entrysavetime, vcolumns[i].value, vcolumns[i].uid)
        createdentries.push(newentry)
      }
      
    }

    // sync with parent
    this.$emit('savedismissentry', createdentries)
    this.showdialog = false
    } 
      catch(err) {
      this.error = err.message
      console.log('DatalogEntryForm: onSaveDataEntry() (threw error)= ', this.error)
    }
  },

},
//***** NON-METHODS *******/
async mounted () {
  for (let i=1; i< this.pheaders.length-1; i++) {
    this.vcolumns.push(this.pheaders[i])
  }
  for (let i=0; i < this.vcolumns.length; i++) {
    this.vcolumns[i].value = ""
  }
  this.onResetForm()
},

}

</script>

<style>
.v-time-picker-title__time .v-picker__title__btn, .v-time-picker-title__time span {
    display: flex;
    justify-content: center;
    height: 20px;
    font-size: 30px;
    -webkit-box-pack: end;
}

</style>